import React from 'react'
import * as styles from '../assets/styles.module.scss'
import { default as Header } from '../components/header'
import { default as Footer } from '../components/footer'

import ModelGrid from '../components/ModelGrid'

const Models = () => {
    return(
        <section className={styles.Container}>
            <Header />
            <div className={styles.Models}>
                <h1>Meet our models</h1>
                <ModelGrid />
            </div>
            <Footer />
        </section>
    )
}

export default Models