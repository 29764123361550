import React, { useState, useEffect } from 'react'
import * as styles from '../assets/styles.module.scss'
import ApiService from '../data/services/ApiService'

interface Model {
    id: string
    avatar: string
    name: string
    age: number
    description: string
    slug: string
    createdAt: Date
    updatedAt: Date
}

const ModelGrid = () => {
    const [models, setModels] = useState([])
    const [baseURL] = useState(ApiService.getBaseURL())

    useEffect(() => {
        (async () => {
            await fetchModels()
        })()
    }, [])

    async function fetchModels() {
        const response = await ApiService.getModels()

        setModels(response.data.models)
    }

    return(
        <div className={styles.ModelGrid}>
            {models.map((model: Model, i) => {
                return(
                    <div key={i} className={styles.Model}>
                        <a href={`/model?model=${model.slug}`}>
                            <img src={`${baseURL}/uploads/models/${model.avatar}`} alt={model.name} title={model.name} />
                        </a>
                    </div>
                )
            })}
        </div>
    )
}

export default ModelGrid